import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import {createMarkup} from "../helpers/helpers"

export default ({data}) => {
  const {
    pageData: {
      about
    }
  } = data

  return (
  <Layout title="About Us">
    <SEO title="About Us" />
    <section>
      <div className="container">
        <div className="columns is-8 is-variable">
          <div className="column testimonies" style={{ 
            fontFamily: `Georgia, Times New Roman, serif`,
            fontSize: `1.3em`,
            lineHeight: `1.7em`}}>
            <h2 className="title" dangerouslySetInnerHTML={createMarkup(about.title)} />
            <div dangerouslySetInnerHTML={createMarkup(about.copy)}/>
        </div>
      </div>
    </div>
  </section>
</Layout>
)}

export const query = graphql`
  query aboutQuery {
    pageData {
      about {
        title
        copy
      }
    }
  }
`